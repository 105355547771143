

$grays-dark: (
  '50': #0f111a,
  '100': $gray-1000,
  '200': $gray-900,
  '300': #373e53,
  '400': $gray-800,
  '500': #606981,
  '600': $gray-600,
  '700': $gray-500,
  '800': #949db5,
  '900': $gray-400,
  '1000': $gray-300,
  '1100': #EFF1F5
) !default;

$primary-dark: $blue-300 !default;
$secondary-dark: $gray-900 !default;
$success-dark: $green-300 !default;
$info-dark: $cyan-300 !default;
$warning-dark: $orange-300 !default;
$danger-dark: $red-300 !default;


$theme-colors-dark: (
  'primary': $primary-dark,
  'secondary': $secondary-dark,
  'success': $success-dark,
  'info': $info-dark,
  'warning': $warning-dark,
  'danger': $danger-dark,
) !default;

//
// Global colors
//
// emphasis colors need to discuss with designers
$primary-text-emphasis-dark:    $blue-300 !default;
$secondary-text-emphasis-dark:  $gray-300 !default; 
$success-text-emphasis-dark:    $green-300 !default;
$info-text-emphasis-dark:       $cyan-300 !default;
$warning-text-emphasis-dark:    $orange-300 !default;
$danger-text-emphasis-dark:     $red-300 !default;
$light-text-emphasis-dark:      $gray-100 !default;
$dark-text-emphasis-dark:       $gray-1100 !default;


// scss-docs-start theme-bg-subtle-variables
$primary-bg-subtle-dark:        #1C2231 !default;
$secondary-bg-subtle-dark:      #252834 !default;
$success-bg-subtle-dark:        #1D2527 !default;
$info-bg-subtle-dark:           #1A2431 !default;
$warning-bg-subtle-dark:        #262527 !default;
$danger-bg-subtle-dark:         #241F25 !default;
// scss-docs-end theme-bg-subtle-variables

// scss-docs-start theme-border-subtle-dark-variables


$primary-border-subtle-dark:       #303B5A !default;
$secondary-border-subtle-dark:     #3D414C !default;
$success-border-subtle-dark:       #354739 !default;
$info-border-subtle-dark:          #29435A !default;
$warning-border-subtle-dark:       #51453A !default;
$danger-border-subtle-dark:        #4C3233 !default;
$light-border-subtle-dark:          $gray-1100 !default;
$dark-border-subtle-dark:           $gray-800 !default;
// scss-docs-end theme-border-subtle-dark-variables



$body-color-dark:                   $gray-400 !default;
$body-bg-dark:                      map-get($grays-dark, '50') !default;
$body-secondary-color-dark:         map-get($grays-dark, '800') !default;
$body-secondary-bg-dark:            $gray-900 !default;
$body-tertiary-color-dark:          $gray-500 !default;
$body-tertiary-bg-dark:             map-get($grays-dark, '300') !default;
$body-emphasis-color-dark:          $gray-100 !default;
$body-emphasis-bg-dark:             $gray-1100 !default;
$headings-color-dark:               var(--#{$prefix}emphasis-color) !default;
$link-color-dark:                   map-get($theme-colors-dark, 'primary') !default;
$link-hover-color-dark:             $primary-text-emphasis-dark !default;

$code-color-dark:                   $pink !default;
$body-quaternary-color-dark:        map-get($grays-dark, '500') !default;
$body-quaternary-bg-dark:           $gray-800 !default;
$body-highlight-color-dark:         $gray-300 !default;
$body-highlight-bg-dark:            $gray-1000 !default;


//*-----------------------------------------------
//|   Border
//-----------------------------------------------*/
$border-color-dark: map-get($grays-dark, '300') !default;
$border-color-translucent-dark: rgba($border-color-dark, .78) !default;

//*-----------------------------------------------
//|   Color shades
//-----------------------------------------------*/
$theme-color-shades-dark: (
  'primary-lighter': $blue-700,
  'primary-light': $blue-500,
  'primary-dark': $blue-300,
  'primary-darker': $blue-300,

  'warning-lighter': $orange-700,
  'warning-light': $orange-500,
  'warning-dark': $orange-300,
  'warning-darker': $orange-300,

  'danger-lighter': $red-700,
  'danger-light': $red-500,
  'danger-dark': $red-300,
  'danger-darker': $red-300,

  'success-lighter': $green-700,
  'success-light': $green-500,
  'success-dark': $green-300,
  'success-darker': $green-300,

  'info-lighter': $cyan-700,
  'info-light': $cyan-500,
  'info-dark': $cyan-300,
  'info-darker': $cyan-300,
) !default;

//
// Forms
//
$form-switch-color-dark: $form-switch-color !default;
$form-select-indicator-dark: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTUwIiBoZWlnaHQ9IjE1MCIgdmlld0JveD0iMCAwIDE1MCAxNTAiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik03NS4zNDggMTI3LjE5MkM3Mi40MzgxIDEyNy4xOTIgNjkuODUxNCAxMjYuMjIyIDY3LjkxMTUgMTI0LjI4Mkw1LjgzMjE1IDYyLjIwMjNDMS42Mjg4NyA1OC4zMjIzIDEuNjI4ODcgNTEuNTMyNCA1LjgzMjE1IDQ3LjY1MjVDOS43MTIxMSA0My40NDkyIDE2LjUwMiA0My40NDkyIDIwLjM4MiA0Ny42NTI1TDc1LjM0OCAxMDIuMjk1TDEyOS45OTEgNDcuNjUyNUMxMzMuODcxIDQzLjQ0OTIgMTQwLjY2MSA0My40NDkyIDE0NC41NDEgNDcuNjUyNUMxNDguNzQ0IDUxLjUzMjQgMTQ4Ljc0NCA1OC4zMjIzIDE0NC41NDEgNjIuMjAyM0w4Mi40NjEzIDEyNC4yODJDODAuNTIxMyAxMjYuMjIyIDc3LjkzNDcgMTI3LjE5MiA3NS4zNDggMTI3LjE5MloiIGZpbGw9IiM5RkE2QkMiLz4KPC9zdmc+Cg==') !default;

// scss-docs-start form-validation-colors-dark
$form-valid-color-dark:             $green !default;
$form-valid-border-color-dark:      $green !default;
$form-invalid-color-dark:           $red !default;
$form-invalid-border-color-dark:    $red !default;
// scss-docs-end form-validation-colors-dark

//
// Accordion
//
$accordion-icon-color-dark: $primary-text-emphasis-dark !default;
$accordion-icon-active-color-dark:  $primary-text-emphasis-dark !default;
$accordion-button-icon-dark: url("data:image/svg+xml,%3Csvg aria-hidden='true' focusable='false' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='-200 -200 900 900'%3E%3Cpath fill='#{$accordion-icon-color-dark}' d='M416 208H272V64c0-17.67-14.33-32-32-32h-32c-17.67 0-32 14.33-32 32v144H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h144v144c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32V304h144c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z'%3E%3C/path%3E%3C/svg%3E") !default;
$accordion-button-active-icon-dark: url("data:image/svg+xml,%3Csvg aria-hidden='true' focusable='false' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='-200 -200 900 900'%3E%3Cpath fill='#{$accordion-icon-active-color-dark}' d='M416 208H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h384c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z'%3E%3C/path%3E%3C/svg%3E") !default;

// carousel-dark-variables
$carousel-dark-indicator-active-bg:  $primary !default;
$carousel-dark-control-icon-filter:  none !default;

// //list group
// $list-group-bg-dark: #{map-get($dark-common, 'white')} !default;
// $list-group-action-hover-color-dark: #{map-get($grays, '300')} !default;
// $list-group-hover-bg-dark: #{map-get($grays, '1200')} !default;

