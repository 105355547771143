
//Main Theme Variables
$backgroundColor: #888 !default; //background color of tabulator
$borderColor:#999 !default; //border to tabulator
$textSize:14px !default; //table text size

//header theming
$headerBackgroundColor:#e6e6e6 !default; //border to tabulator
$headerTextColor:#555 !default; //header text color
$headerBorderColor:#aaa !default;  //header border color
$headerSeparatorColor:#999 !default; //header bottom separator color
$headerMargin:4px !default; //padding round header

//column header arrows
$sortArrowHover: #555 !default;
$sortArrowActive: #666 !default;
$sortArrowInactive: #bbb !default;

$columnResizeGuideColor:#999 !default;

//row theming
$rowBackgroundColor: transparent !default; //table row background color
$rowAltBackgroundColor:#EFEFEF !default; //table row background color
$rowBorderColor:#aaa !default; //table border color
$rowTextColor:#333 !default; //table text color
$rowHoverBackground:#bbb !default; //row background color on hover

$rowSelectedBackground: #9ABCEA !default; //row background color when selected
$rowSelectedBackgroundHover: #769BCC !default;//row background color when selected and hovered

$editBoxColor:#1D68CD !default; //border color for edit boxes
$errorColor:#dd0000 !default; //error indication

//footer theming
$footerBackgroundColor:#e6e6e6 !default; //border to tabulator
$footerTextColor:#555 !default; //footer text color
$footerBorderColor:#aaa !default; //footer border color
$footerSeparatorColor:#999 !default; //footer bottom separator color
$footerActiveColor:#d00 !default; //footer bottom active text color

$spreadsheetActiveTabColor:#fff !default; //color for the active spreadsheet tab

//range selection
$rangeBorderColor: #2975DD !default; //range border color
$rangeHandleColor: $rangeBorderColor !default; //range handle color
$rangeHeaderSelectedBackground: #3876ca !default; //header background color when selected
$rangeHeaderSelectedTextColor: #FFFFFF !default; //header text color when selected
$rangeHeaderHighlightBackground: #D6D6D6 !default; //header background color when highlighted
$rangeHeaderTextHighlightBackground: #000000 !default; //header text color when highlighted

//Tabulator Containing Element
.tabulator{
	position: relative;
	
	border: 1px solid $borderColor;
	
	background-color: $backgroundColor;
	
	font-size:$textSize;
	text-align: left;
	overflow:hidden;
	
	-webkit-transform: translateZ(0);
	-moz-transform: translateZ(0);
	-ms-transform: translateZ(0);
	-o-transform: translateZ(0);
	transform: translateZ(0);
	
	&[tabulator-layout="fitDataFill"]{
		.tabulator-tableholder{
			.tabulator-table{
				min-width:100%;
			}
		}
	}
	
	&[tabulator-layout="fitDataTable"]{
		display: inline-block;
	}
	
	&.tabulator-block-select{
		user-select: none;
	}
	
	&.tabulator-ranges{
		.tabulator-cell:not(.tabulator-editing){
			user-select: none;
		}
	}
	
	
	//column header containing element
	.tabulator-header{
		position:relative;
		box-sizing: border-box;
		
		width:100%;
		
		border-bottom:1px solid $headerSeparatorColor;
		background-color: $headerBackgroundColor;
		color: $headerTextColor;
		font-weight:bold;
		
		white-space: nowrap;
		overflow:hidden;
		
		-moz-user-select: none;
		-khtml-user-select: none;
		-webkit-user-select: none;
		-o-user-select: none;
		
		outline: none;
		
		&.tabulator-header-hidden{
			display:none;
		}
		
		.tabulator-header-contents{
			position: relative;
			overflow: hidden;
			
			.tabulator-headers{
				display: inline-block;
			}
		}
		
		//individual column header element
		.tabulator-col{
			display:inline-flex;
			position:relative;
			box-sizing:border-box;
			
			flex-direction: column;
			justify-content: flex-start;
			
			border-right:1px solid $headerBorderColor;
			background:$headerBackgroundColor;
			
			text-align:left;
			vertical-align: bottom;
			overflow: hidden;
			
			&.tabulator-moving{
				position: absolute;
				border:1px solid $headerSeparatorColor;
				background:darken($headerBackgroundColor, 10%);
				pointer-events: none;
			}
			
			&.tabulator-range-highlight{
				background-color: $rangeHeaderHighlightBackground;
				color: $rangeHeaderTextHighlightBackground;
			}
			
			&.tabulator-range-selected{
				background-color: $rangeHeaderSelectedBackground;
				color: $rangeHeaderSelectedTextColor;
			}
			
			//hold content of column header
			.tabulator-col-content{
				box-sizing:border-box;
				position: relative;
				padding:4px;
				
				//header menu button
				.tabulator-header-popup-button{
					padding: 0 8px;
					
					&:hover{
						cursor: pointer;
						opacity: .6;
					}
				}
				
				//hold title and sort arrow
				.tabulator-col-title-holder{
					position: relative;
				}
				
				//hold title of column header
				.tabulator-col-title{
					box-sizing:border-box;
					width: 100%;
					
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
					vertical-align:bottom;
					
					&.tabulator-col-title-wrap{
						white-space: normal;
						text-overflow: initial;
					}
					
					//element to hold title editor
					.tabulator-title-editor{
						box-sizing: border-box;
						width: 100%;
						
						border:1px solid #999;
						
						padding:1px;
						
						background: #fff;
					}
					
					.tabulator-header-popup-button + .tabulator-title-editor{
						width:calc(100% - 22px);
					}
				}
				
				//column sorter arrow
				.tabulator-col-sorter{
					display: flex;
					align-items: center;
					
					position: absolute;
					top:0;
					bottom:0;
					right:4px;
					
					.tabulator-arrow{
						width: 0;
						height: 0;
						border-left: 6px solid transparent;
						border-right: 6px solid transparent;
						border-bottom: 6px solid $sortArrowInactive;
					}
				}
				
			}
			
			//complex header column group
			&.tabulator-col-group{
				
				//gelement to hold sub columns in column group
				.tabulator-col-group-cols{
					position:relative;
					display: flex;
					
					border-top:1px solid $headerBorderColor;
					overflow: hidden;
					
					margin-right:-1px;
				}
			}
			
			//header filter containing element
			.tabulator-header-filter{
				position: relative;
				box-sizing: border-box;
				margin-top:2px;
				width:100%;
				text-align: center;
				
				//styling adjustment for inbuilt editors
				textarea{
					height:auto !important;
				}
				
				svg{
					margin-top: 3px;
				}
				
				input{
					&::-ms-clear {
						width : 0;
						height: 0;
					}
				}
			}
			
			//styling child elements for sortable columns
			&.tabulator-sortable{
				.tabulator-col-title{
					padding-right:25px;
				}
				
				@media (hover:hover) and (pointer:fine){
					&.tabulator-col-sorter-element:hover{
						cursor:pointer;
						background-color:darken($headerBackgroundColor, 10%);
					}
				}
				
				&[aria-sort="none"]{
					.tabulator-col-content .tabulator-col-sorter{
						color: $sortArrowInactive;
						
						@media (hover:hover) and (pointer:fine){
							&.tabulator-col-sorter-element .tabulator-arrow:hover{
								cursor:pointer;
								border-bottom: 6px solid $sortArrowHover;
							}
						}
						
						.tabulator-arrow{
							border-top: none;
							border-bottom: 6px solid $sortArrowInactive;
						}
					}
				}
				
				&[aria-sort="ascending"]{
					.tabulator-col-content .tabulator-col-sorter{
						color: $sortArrowActive;
						
						@media (hover:hover) and (pointer:fine){
							&.tabulator-col-sorter-element .tabulator-arrow:hover{
								cursor:pointer;
								border-bottom: 6px solid $sortArrowHover;
							}
						}
						
						.tabulator-arrow{
							border-top: none;
							border-bottom: 6px solid $sortArrowActive;
							
						}
					}
				}
				
				&[aria-sort="descending"]{
					.tabulator-col-content .tabulator-col-sorter{
						color: $sortArrowActive;
						
						@media (hover:hover) and (pointer:fine){
							&.tabulator-col-sorter-element .tabulator-arrow:hover{
								cursor:pointer;
								border-top: 6px solid $sortArrowHover;
							}
						}
						
						.tabulator-arrow{
							border-bottom: none;
							border-top: 6px solid $sortArrowActive;
							color: $sortArrowActive;
						}
					}
				}
			}
			
			
			&.tabulator-col-vertical{
				.tabulator-col-content{
					.tabulator-col-title{
						writing-mode: vertical-rl;
						text-orientation: mixed;
						
						display:flex;
						align-items:center;
						justify-content:center;
					}
				}
				
				&.tabulator-col-vertical-flip{
					.tabulator-col-title{
						transform: rotate(180deg);
					}
				}
				
				&.tabulator-sortable{
					.tabulator-col-title{
						padding-right:0;
						padding-top:20px;
					}
					
					&.tabulator-col-vertical-flip{
						.tabulator-col-title{
							padding-right:0;
							padding-bottom:20px;
						}
						
					}
					
					.tabulator-col-sorter{
						justify-content: center;
						left:0;
						right:0;
						top:4px;
						bottom:auto;
					}
				}
			}
		}
		
		.tabulator-frozen{
			position: sticky;
			left:0;
			
			// background-color: inherit;
			
			z-index: 11;
			
			&.tabulator-frozen-left{
				border-right:2px solid $rowBorderColor;
			}
			
			&.tabulator-frozen-right{
				border-left:2px solid $rowBorderColor;
			}
		}
		
		
		.tabulator-calcs-holder{
			box-sizing:border-box;
			display: inline-block;
			
			background:lighten($headerBackgroundColor, 5%) !important;
			
			border-top:1px solid $rowBorderColor;
			border-bottom:1px solid $headerBorderColor;
			
			// overflow: hidden;
			
			.tabulator-row{
				background:lighten($headerBackgroundColor, 5%) !important;
				
				.tabulator-col-resize-handle{
					display: none;
				}
			}
		}
		
		.tabulator-frozen-rows-holder{	
			display: inline-block;	
			&:empty{
				display: none;
			}
		}
	}
	
	//scrolling element to hold table
	.tabulator-tableholder{
		position:relative;
		width:100%;
		white-space: nowrap;
		overflow:auto;
		-webkit-overflow-scrolling: touch;
		
		&:focus{
			outline: none;
		}
		
		//default placeholder element
		.tabulator-placeholder{
			box-sizing:border-box;
			display: flex;
			align-items:center;
			justify-content: center;
			
			min-width:100%;
			width:100%;
			
			&[tabulator-render-mode="virtual"]{
				min-height:100%;
			}
			
			.tabulator-placeholder-contents {
				display: inline-block;
				text-align: center;
				
				padding:10px;
				
				color:#ccc;
				font-weight: bold;
				font-size: 20px;
				
				white-space: normal;
			}
		}
		
		//element to hold table rows
		.tabulator-table{
			position:relative;
			display:inline-block;
			background-color:$rowBackgroundColor;
			white-space: nowrap;
			overflow:visible;
			color:$rowTextColor;
			
			//row element
			.tabulator-row{
				&.tabulator-calcs{
					font-weight: bold;
					background:darken($rowAltBackgroundColor, 5%) !important;
					
					&.tabulator-calcs-top{
						border-bottom:2px solid $rowBorderColor;
					}
					
					&.tabulator-calcs-bottom{
						border-top:2px solid $rowBorderColor;
					}
				}
			}
			
		}
		
		.tabulator-range-overlay {
			position: absolute;
			inset: 0;
			z-index: 10;
			pointer-events: none;
			
			.tabulator-range {
				position: absolute;
				box-sizing: border-box;
				border: 1px solid $rangeBorderColor;
				
				&.tabulator-range-active::after {
					content: '';
					position: absolute;
					right: -3px;
					bottom: -3px;
					width: 6px;
					height: 6px;
					background-color: $rangeHandleColor;
					border-radius: 999px;
				}
			}
			
			.tabulator-range-cell-active {
				position: absolute;
				box-sizing: border-box;
				border: 2px solid $rangeBorderColor;
			}
		}
	}
	
	//footer element
	.tabulator-footer{
		border-top:1px solid $footerSeparatorColor;
		background-color: $footerBackgroundColor;
		
		color: $footerTextColor;
		font-weight:bold;
		white-space:nowrap;
		user-select:none;
		
		-moz-user-select: none;
		-khtml-user-select: none;
		-webkit-user-select: none;
		-o-user-select: none;
		
		.tabulator-footer-contents {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: space-between;
			
			padding:5px 10px;
			
			&:empty{
				display: none;
			}
		}
		
		.tabulator-spreadsheet-tabs{
			margin-top:-5px;
			
			overflow-x: auto;
			
			.tabulator-spreadsheet-tab{
				display: inline-block;
				padding:5px;
				
				border:$borderColor 1px solid;
				border-top: none;
				border-bottom-left-radius: 5px;
				border-bottom-right-radius: 5px;
				
				font-size: .9em;
				
				&:hover{
					cursor:pointer;
					opacity: .7;
				}
				
				&.tabulator-spreadsheet-tab-active{
					background: $spreadsheetActiveTabColor;
				}
			}
		}
		
		.tabulator-calcs-holder{
			box-sizing:border-box;
			width:100%;
			
			text-align: left;
			
			background:lighten($footerBackgroundColor, 5%) !important;
			border-bottom:1px solid $rowBorderColor;
			border-top:1px solid $rowBorderColor;
			
			overflow: hidden;
			
			.tabulator-row{
				display: inline-block;
				background:lighten($footerBackgroundColor, 5%) !important;
				
				.tabulator-col-resize-handle{
					display: none;
				}
			}
			
			
			&:only-child{
				margin-bottom:-5px;
				border-bottom:none;
			}
		}
		
		&>*+.tabulator-page-counter{
			margin-left:10px;
		}
		
		.tabulator-page-counter {
			font-weight: normal;
		}
		
		.tabulator-paginator{
			flex:1;
			
			text-align: right;
			
			color: $footerTextColor;
			font-family:inherit;
			font-weight:inherit;
			font-size:inherit;
		}
		
		//pagination container element
		.tabulator-page-size{
			display:inline-block;
			
			margin:0 5px;
			padding:2px 5px;
			
			border:1px solid $footerBorderColor;
			border-radius:3px;
		}
		
		.tabulator-pages{
			margin:0 7px;
		}
		
		//pagination button
		.tabulator-page{
			display:inline-block;
			
			margin:0 2px;
			padding:2px 5px;
			
			border:1px solid $footerBorderColor;
			border-radius:3px;
			
			background:rgba(255,255,255,.2);
			
			&.active{
				color:$footerActiveColor;
			}
			
			&:disabled{
				opacity:.5;
			}
			
			&:not(disabled){
				@media (hover:hover) and (pointer:fine){
					&:hover{
						cursor:pointer;
						background:rgba(0,0,0,.2);
						color:#fff;
					}
				}
			}
		}
	}
	
	//column resize handles
	.tabulator-col-resize-handle{
		position: relative;
		display: inline-block;
		width: 6px;
		
		margin-left: -3px;
		margin-right: -3px;
		
		z-index: 11;
		vertical-align: middle;
		
		@media (hover:hover) and (pointer:fine){
			&:hover{
				cursor:ew-resize;
			}
		}
		
		&:last-of-type{
			width:3px;
			margin-right:0;
		}
	}
	
	//column resize guide
	.tabulator-col-resize-guide {
		position: absolute;
		top: 0;
		width: 4px;
		height: 100%;
		margin-left: -0.5px;
		background-color: $columnResizeGuideColor;
		opacity: .5;
	}
	
	//row resize guide
	.tabulator-row-resize-guide {
		position: absolute;
		left: 0;
		width: 100%;
		height: 4px;
		margin-top: -0.5px;
		background-color: $columnResizeGuideColor;
		opacity: .5;
	}
	
	
	//holding div that contains loader and covers tabulator element to prevent interaction
	.tabulator-alert{
		position:absolute;
		display: flex;
		align-items:center;
		
		top:0;
		left:0;
		z-index:100;
		
		height:100%;
		width:100%;
		background:rgba(0,0,0,.4);
		text-align:center;
		
		//loading message element
		.tabulator-alert-msg {
			display:inline-block;
			
			margin:0 auto;
			padding:10px 20px;
			
			border-radius:10px;
			
			background:#fff;
			font-weight:bold;
			font-size:16px;
			
			//loading message
			&.tabulator-alert-state-msg {
				border:4px solid #333;
				color:#000;
			}
			
			//error message
			&.tabulator-alert-state-error {
				border:4px solid #D00;
				color:#590000;
			}
		}
	}
}

//row element
.tabulator-row{
	position: relative;
	box-sizing: border-box;
	min-height:$textSize + ($headerMargin * 2);
	background-color: $rowBackgroundColor;
	
	
	&.tabulator-row-even{
		background-color: $rowAltBackgroundColor;
	}
	
	@media (hover:hover) and (pointer:fine){
		&.tabulator-selectable:hover{
			background-color:$rowHoverBackground;
			cursor: pointer;
		}
	}
	
	&.tabulator-selected{
		background-color:$rowSelectedBackground;
	}
	
	@media (hover:hover) and (pointer:fine){
		&.tabulator-selected:hover{
			background-color:$rowSelectedBackgroundHover;
			cursor: pointer;
		}
	}
	
	&.tabulator-row-moving{
		border:1px solid #000;
		background:#fff;
	}
	
	&.tabulator-moving{
		position: absolute;
		
		border-top:1px solid  $rowBorderColor;
		border-bottom:1px solid  $rowBorderColor;
		
		pointer-events: none;
		z-index:15;
	}
	
	&.tabulator-range-highlight{
		.tabulator-cell.tabulator-range-row-header{
			background-color: $rangeHeaderHighlightBackground;
			color: $rangeHeaderTextHighlightBackground;
		}
		
		&.tabulator-range-selected{
			.tabulator-cell.tabulator-range-row-header{
				background-color: $rangeHeaderSelectedBackground;
				color: $rangeHeaderSelectedTextColor;
			}	
		}
	}
	
	
	&.tabulator-range-selected{
		.tabulator-cell.tabulator-range-row-header{
			background-color: $rangeHeaderSelectedBackground;
			color: $rangeHeaderSelectedTextColor;
		}
	}
	
	//row resize handles
	.tabulator-row-resize-handle{
		position:absolute;
		right:0;
		bottom:0;
		left:0;
		height:5px;
		
		&.prev{
			top:0;
			bottom:auto;
		}
		
		@media (hover:hover) and (pointer:fine){
			&:hover{
				cursor:ns-resize;
			}
		}
	}
	
	.tabulator-responsive-collapse{
		box-sizing:border-box;
		
		padding:5px;
		
		border-top:1px solid $rowBorderColor;
		border-bottom:1px solid $rowBorderColor;
		
		&:empty{
			display:none;
		}
		
		table{
			font-size:$textSize;
			
			tr{
				td{
					position: relative;
					
					&:first-of-type{
						padding-right:10px;
					}
				}
			}
		}
	}
	
	//cell element
	.tabulator-cell{
		display:inline-block;
		position: relative;
		box-sizing:border-box;
		padding:4px;
		border-right:1px solid $rowBorderColor;
		vertical-align:middle;
		white-space:nowrap;
		overflow:hidden;
		text-overflow:ellipsis;
		outline:none;
		
		&.tabulator-row-header{
			border-right:1px solid $borderColor;
			border-bottom:1px solid $rowBorderColor;
			background:$headerBackgroundColor;
		}
		
		&.tabulator-frozen{
			display: inline-block;
			position: sticky;
			
			left:0;
			
			background-color: inherit;
			
			z-index: 11;
			
			&.tabulator-frozen-left{
				border-right:2px solid $rowBorderColor;
			}
			
			&.tabulator-frozen-right{
				border-left:2px solid $rowBorderColor;
			}
		}
		
		&.tabulator-editing{
			border:1px solid $editBoxColor;
			outline:none;
			
			padding: 0;
			
			input, select{
				border:1px;
				background:transparent;
				outline:none;
			}
		}
		
		&.tabulator-validation-fail{
			border:1px solid $errorColor;
			
			input, select{
				border:1px;
				background:transparent;
				
				color: $errorColor;
			}
		}
		
		//movable row handle
		&.tabulator-row-handle{
			display: inline-flex;
			align-items:center;
			justify-content:center;
			
			-moz-user-select: none;
			-khtml-user-select: none;
			-webkit-user-select: none;
			-o-user-select: none;
			
			//handle holder
			.tabulator-row-handle-box{
				width:80%;
				
				//Hamburger element
				.tabulator-row-handle-bar{
					width:100%;
					height:3px;
					margin-top:2px;
					background:#666;
				}
			}
		}
		
		&.tabulator-range-selected:not(.tabulator-range-only-cell-selected):not(.tabulator-range-row-header){
			background-color: $rowSelectedBackground;
		}
		
		.tabulator-data-tree-branch-empty{
			display:inline-block;
			width:7px;
		}
		
		.tabulator-data-tree-branch{
			display:inline-block;
			vertical-align:middle;
			
			height:9px;
			width:7px;
			
			margin-top:-9px;
			margin-right:5px;
			
			border-bottom-left-radius:1px;
			
			border-left:2px solid $rowBorderColor;
			border-bottom:2px solid $rowBorderColor;
		}
		
		.tabulator-data-tree-control{
			
			display:inline-flex;
			justify-content:center;
			align-items:center;
			vertical-align:middle;
			
			height:11px;
			width:11px;
			
			margin-right:5px;
			
			border:1px solid $rowTextColor;
			border-radius:2px;
			background:rgba(0, 0, 0, .1);
			
			overflow:hidden;
			
			@media (hover:hover) and (pointer:fine){
				&:hover{
					cursor:pointer;
					background:rgba(0, 0, 0, .2);
				}
			}
			
			.tabulator-data-tree-control-collapse{
				display:inline-block;
				position: relative;
				
				height: 7px;
				width: 1px;
				
				background: transparent;
				
				&:after {
					position: absolute;
					content: "";
					left: -3px;
					top: 3px;
					
					height: 1px;
					width: 7px;
					
					background: $rowTextColor;
				}
			}
			
			.tabulator-data-tree-control-expand{
				display:inline-block;
				position: relative;
				
				height: 7px;
				width: 1px;
				
				background: $rowTextColor;
				
				&:after {
					position: absolute;
					content: "";
					left: -3px;
					top: 3px;
					
					height: 1px;
					width: 7px;
					
					background: $rowTextColor;
				}
			}
			
		}
		
		.tabulator-responsive-collapse-toggle{
			display: inline-flex;
			align-items:center;
			justify-content:center;
			
			-moz-user-select: none;
			-khtml-user-select: none;
			-webkit-user-select: none;
			-o-user-select: none;
			
			height:15px;
			width:15px;
			
			border-radius:20px;
			background:#666;
			
			color:$rowBackgroundColor;
			font-weight:bold;
			font-size:1.1em;
			
			@media (hover:hover) and (pointer:fine){
				&:hover{
					opacity:.7;
					cursor: pointer;
				}
			}
			
			&.open{
				.tabulator-responsive-collapse-toggle-close{
					display:initial;
				}
				
				.tabulator-responsive-collapse-toggle-open{
					display:none;
				}
			}
			
			svg{
				stroke:$rowBackgroundColor;
			}
			
			.tabulator-responsive-collapse-toggle-close{
				display:none;
			}
		}
		
		.tabulator-traffic-light{
			display: inline-block;
			height:14px;
			width:14px;
			
			border-radius:14px;
		}
	}
	
	//row grouping element
	&.tabulator-group{
		box-sizing:border-box;
		border-bottom:1px solid #999;
		border-right:1px solid $rowBorderColor;
		border-top:1px solid #999;
		padding:5px;
		padding-left:10px;
		background:#ccc;
		font-weight:bold;
		
		min-width: 100%;
		
		@media (hover:hover) and (pointer:fine){
			&:hover{
				cursor:pointer;
				background-color:rgba(0,0,0,.1);
			}
		}
		
		&.tabulator-group-visible{
			
			.tabulator-arrow{
				margin-right:10px;
				border-left: 6px solid transparent;
				border-right: 6px solid transparent;
				border-top: 6px solid $sortArrowActive;
				border-bottom: 0;
			}
			
		}
		
		&.tabulator-group-level-1{
			padding-left:30px;
		}
		
		&.tabulator-group-level-2{
			padding-left:50px;
		}
		
		&.tabulator-group-level-3{
			padding-left:70px;
		}
		
		&.tabulator-group-level-4{
			padding-left:90px;
		}
		
		&.tabulator-group-level-5{
			padding-left:110px;
		}
		
		.tabulator-group-toggle{
			display: inline-block;
		}
		
		//sorting arrow
		.tabulator-arrow{
			display: inline-block;
			width: 0;
			height: 0;
			margin-right:16px;
			border-top: 6px solid transparent;
			border-bottom: 6px solid transparent;
			border-right: 0;
			border-left: 6px solid $sortArrowActive;
			vertical-align:middle;
		}
		
		span{
			margin-left:10px;
			color:#d00;
		}
	}
	
}

.tabulator-toggle{
	box-sizing: border-box;

	display: flex;
	flex-direction: row;

	border:1px solid #ccc;
	background:#dcdcdc;

	&.tabulator-toggle-on{
		background:#1c6cc2;
	}
	
	.tabulator-toggle-switch{
		box-sizing: border-box;
		border:1px solid #ccc;
		
		background:#fff;
	}
}

.tabulator-popup-container{
	position: absolute;
	display:inline-block;
	box-sizing:border-box;
	
	background:$rowBackgroundColor;
	border:1px solid $rowBorderColor;
	box-shadow: 0 0 5px 0 rgba(0, 0, 0, .2);
	
	font-size:$textSize;
	
	overflow-y:auto;
	-webkit-overflow-scrolling: touch;
	
	z-index: 10000;
}

.tabulator-popup{
	padding:5px;
	
	border-radius: 3px;
}

.tabulator-tooltip{
	max-width: Min(500px, 100%);
	
	padding:3px 5px;
	
	border-radius: 2px;
	box-shadow:none;
	
	font-size:12px;
	
	pointer-events: none;
}

.tabulator-menu{
	.tabulator-menu-item{
		position:relative;
		box-sizing:border-box;
		
		padding:5px 10px;
		
		user-select: none;
		
		&.tabulator-menu-item-disabled{
			opacity: .5;
		}
		
		@media (hover:hover) and (pointer:fine){
			&:not(.tabulator-menu-item-disabled):hover{
				cursor: pointer;
				background: $rowAltBackgroundColor;
			}
		}
		
		&.tabulator-menu-item-submenu{
			padding-right:25px;
			
			&::after {
				display: inline-block;
				position: absolute;
				
				top: calc(5px + .4em);
				right: 10px;
				height: 7px;
				width: 7px;
				
				content: '';
				
				border-width: 1px 1px 0 0;
				border-style: solid;
				border-color: $rowBorderColor;
				vertical-align: top;
				
				transform: rotate(45deg);
			}
		}
	}
	
	.tabulator-menu-separator{
		border-top:1px solid $rowBorderColor;
	}
}

.tabulator-edit-list{
	max-height:200px;
	
	font-size:$textSize;
	
	overflow-y:auto;
	-webkit-overflow-scrolling: touch;
	
	.tabulator-edit-list-item{
		padding:4px;
		
		color:$rowTextColor;
		outline:none;
		
		&.active{
			color:$rowBackgroundColor;
			background:$editBoxColor;
			
			&.focused{
				outline:1px solid rgba($rowBackgroundColor, .5);
			}
		}
		
		&.focused{
			outline:1px solid $editBoxColor;
		}
		
		@media (hover:hover) and (pointer:fine){
			&:hover{
				cursor:pointer;
				
				color:$rowBackgroundColor;
				background:$editBoxColor;
			}
		}
	}
	
	.tabulator-edit-list-placeholder{
		padding:4px;
		
		color:$rowTextColor;
		text-align: center;
	}
	
	.tabulator-edit-list-group{
		border-bottom:1px solid $rowBorderColor;
		
		padding:4px;
		padding-top:6px;
		
		color:$rowTextColor;
		font-weight:bold;
		
	}
	
	.tabulator-edit-list-item, .tabulator-edit-list-group{
		&.tabulator-edit-list-group-level-2{
			padding-left:12px;
		}
		
		&.tabulator-edit-list-group-level-3{
			padding-left:20px;
		}
		
		&.tabulator-edit-list-group-level-4{
			padding-left:28px;
		}
		
		&.tabulator-edit-list-group-level-5{
			padding-left:36px;
		}
	}
}


//RTL Styling

.tabulator.tabulator-ltr{
	direction: ltr;
}

.tabulator.tabulator-rtl{
	text-align: initial;
	direction: rtl;
	
	.tabulator-header {
		.tabulator-col{
			text-align: initial;
			border-left:1px solid $headerBorderColor;
			border-right:initial;
			
			&.tabulator-col-group{
				.tabulator-col-group-cols{
					margin-right:initial;
					margin-left:-1px;
				}
			}
			
			&.tabulator-sortable{
				.tabulator-col-title{
					padding-right:0;
					padding-left:25px;
				}
			}
			
			.tabulator-col-content{
				.tabulator-col-sorter{
					left:8px;
					right:initial;
				}
			}
		}
	}
	
	.tabulator-tableholder{
		.tabulator-range-overlay {
			.tabulator-range {
				&.tabulator-range-active::after {
					content: '';
					position: absolute;
					left: -3px;
					right:initial;
					bottom: -3px;
					width: 6px;
					height: 6px;
					background-color: $rangeHandleColor;
					border-radius: 999px;
				}
			}
		}
	}
	
	.tabulator-row{
		.tabulator-cell{
			border-right:initial;
			border-left:1px solid $rowBorderColor;
			
			.tabulator-data-tree-branch{
				margin-right:initial;
				margin-left:5px;
				
				border-bottom-left-radius:initial;
				border-bottom-right-radius:1px;
				
				border-left:initial;
				border-right:2px solid $rowBorderColor;
			}
			
			.tabulator-data-tree-control{
				margin-right:initial;
				margin-left:5px;
			}
			
			&.tabulator-frozen{
				
				&.tabulator-frozen-left{
					border-left:2px solid $rowBorderColor;
				}
				
				&.tabulator-frozen-right{
					border-right:2px solid $rowBorderColor;
				}
			}
		}
		
		.tabulator-col-resize-handle{
			&:last-of-type{
				width:3px;
				margin-left:0;
				margin-right:-3px;
			}
		}
		
	}
	
	.tabulator-footer {
		.tabulator-calcs-holder{
			text-align: initial;
		}
		
	}
	
}


// Table print styling

.tabulator-print-fullscreen{
	position: absolute;
	top:0;
	bottom:0;
	left:0;
	right:0;
	
	z-index: 10000;
}

body.tabulator-print-fullscreen-hide>*:not(.tabulator-print-fullscreen){
	display:none !important;
}

.tabulator-print-table{
	border-collapse: collapse;
	
	.tabulator-data-tree-branch{
		display:inline-block;
		vertical-align:middle;
		
		height:9px;
		width:7px;
		
		margin-top:-9px;
		margin-right:5px;
		
		border-bottom-left-radius:1px;
		
		border-left:2px solid $rowBorderColor;
		border-bottom:2px solid $rowBorderColor;
	}
	
	//row grouping element
	.tabulator-print-table-group{
		box-sizing:border-box;
		border-bottom:1px solid #999;
		border-right:1px solid $rowBorderColor;
		border-top:1px solid #999;
		padding:5px;
		padding-left:10px;
		background:#ccc;
		font-weight:bold;
		
		min-width: 100%;
		
		@media (hover:hover) and (pointer:fine){
			&:hover{
				cursor:pointer;
				background-color:rgba(0,0,0,.1);
			}
		}
		
		&.tabulator-group-visible{
			
			.tabulator-arrow{
				margin-right:10px;
				border-left: 6px solid transparent;
				border-right: 6px solid transparent;
				border-top: 6px solid $sortArrowActive;
				border-bottom: 0;
			}
			
		}
		
		&.tabulator-group-level-1{
			td{
				padding-left:30px !important;
			}
		}
		
		&.tabulator-group-level-2{
			td{
				padding-left:50px !important;
			}
		}
		
		&.tabulator-group-level-3{
			td{
				padding-left:70px !important;
			}
		}
		
		&.tabulator-group-level-4{
			td{
				padding-left:90px !important;
			}
		}
		
		&.tabulator-group-level-5{
			td{
				padding-left:110px !important;
			}
		}
		
		.tabulator-group-toggle{
			display: inline-block;
		}
		
		//sorting arrow
		.tabulator-arrow{
			display: inline-block;
			width: 0;
			height: 0;
			margin-right:16px;
			border-top: 6px solid transparent;
			border-bottom: 6px solid transparent;
			border-right: 0;
			border-left: 6px solid $sortArrowActive;
			vertical-align:middle;
		}
		
		span{
			margin-left:10px;
			color:#d00;
		}
	}
	
	.tabulator-data-tree-control{
		
		display:inline-flex;
		justify-content:center;
		align-items:center;
		vertical-align:middle;
		
		height:11px;
		width:11px;
		
		margin-right:5px;
		
		border:1px solid $rowTextColor;
		border-radius:2px;
		background:rgba(0, 0, 0, .1);
		
		overflow:hidden;
		
		@media (hover:hover) and (pointer:fine){
			&:hover{
				cursor:pointer;
				background:rgba(0, 0, 0, .2);
			}
		}
		
		.tabulator-data-tree-control-collapse{
			display:inline-block;
			position: relative;
			
			height: 7px;
			width: 1px;
			
			background: transparent;
			
			&:after {
				position: absolute;
				content: "";
				left: -3px;
				top: 3px;
				
				height: 1px;
				width: 7px;
				
				background: $rowTextColor;
			}
		}
		
		.tabulator-data-tree-control-expand{
			display:inline-block;
			position: relative;
			
			height: 7px;
			width: 1px;
			
			background: $rowTextColor;
			
			&:after {
				position: absolute;
				content: "";
				left: -3px;
				top: 3px;
				
				height: 1px;
				width: 7px;
				
				background: $rowTextColor;
			}
		}
	}
}
